import React from 'react';

function App() {
  return (
    <div className="bg-blue-500 h-screen flex items-center justify-center">
      <h1 className="text-white text-4xl font-bold">
        Welcome to PaperDig!
      </h1>
    </div>
  );
}

export default App;
